@use "/src/styles/contentWrap.module.scss";
.content-wrap {
	:global .li-value {
		flex: 1;
	}
	font-size: 14px;

	input {
		font-size: 14px;
	}
}

.btnGroups {
	margin-top: 52px;
	display: flex;
	justify-content: center;
	button {
		width: 80px;
	}
}

.regenSecretBtn {
	width: fit-content;
}

.selectInputWithoutPadding {
	width: 134px;
	div {
		font-size: 14px;
		padding: 4px 12px;
	}
}

.li-container {
	margin-bottom: 20px;
	input {
		width: 100%;
	}
}

// @media only screen and (max-width: 884px) {
// 	.regenSecretWarning {
// 		color: #29abc1;
// 		bottom: -48px;
// 	}
// }
