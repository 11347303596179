.content-wrap {
	background-color: var(--white);
	border: 1px solid var(--disabled);
	border-radius: 10px;
	padding: 30px;
	button {
		border-radius: 6px;
	}

	.title {
		font-size: 20px;
		font-weight: 800;
		margin-bottom: 30px;
	}
	.flexContainer {
		display: flex;
		align-items: center;
	}

	.forCopyText {
		margin-left: 4px;
		text-decoration: underline;
		color: #29abc1;
		margin-right: 4px;
	}

	.copySecretContainer {
		cursor: pointer;
	}

	.regenSecretContainer {
		position: relative;
	}

	.regenSecretWarning {
		display: flex;
		align-items: center;
		font-size: 14px;
		position: absolute;
		bottom: -24px;
		span {
			margin-left: 4px;
		}
	}
	@media only screen and (max-width: 884px) {
		.regenSecretWarning {
			line-height: 16px;
			bottom: -36px;
		}
	}

	.pendingRegenerateSecretText {
		position: relative;
		top: 4px;
		background-color: #dddddd;
		padding: 6px 6px 0 6px;
		border-radius: 4px;
	}
	.successRegeneratedSecretText {
		background-color: #dddddd;
		padding: 2px 6px 0 2px;
		margin-left: 4px;
		border-radius: 6px;
		cursor: pointer;
	}

	.confirmBtn {
		margin-top: 36px;
		font-size: 12px;
	}

	.feature-container {
		display: flex;
		max-width: 1000px;

		.form-container {
			border: 1px solid var(--disabled);
			border-radius: 10px;
			padding: 20px;

			.li-container {
				.li-label {
					flex: 1;
				}
				.li-value {
					flex: 2;
				}
			}
		}
	}

	.li-containers-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		max-width: 1000px;

		.li-container:last-child {
			margin-bottom: 20px;
		}
	}

	.li-container {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 20px;

		.li-label {
			min-width: 200px;
		}

		.li-label-fit-content {
			width: fit-content;
			margin-right: 10px;
		}

		.li-value {
			width: 300px;
			display: flex;
			flex-direction: column;
			& input {
				padding: 5px;
			}
		}

		.li-disabled {
			color: var(--disabled);
		}

		.li-enabled {
			color: var(--status);
		}
	}

	.li-container:last-child {
		margin-bottom: 0;
	}

	.li-button {
		// margin-left: 100px;
		// margin-bottom: 30px;
		display: flex;
		justify-content: center;
		// margin-top: 52px;
	}
	input {
		width: 300px;
	}

	.li-button-long button {
		width: 100px;
		margin-top: 30px;
	}

	.li-button-auth {
		margin-left: 0;
	}

	.li-button-role {
		margin-left: 0;
		margin-bottom: 0;
	}

	.li-container-edit-user {
		max-width: 400px;
		div {
			flex: 1;
		}
	}
	.editAppBtn {
		display: flex;
		justify-content: center;
		margin-left: 0;
		margin-top: 52px;
	}

	.selectInputWithoutPadding {
		div {
			padding: 5px 12px;
		}
	}

	button:first-child {
		margin-right: 5px;
	}

	.side-button {
		margin-left: 20px;
		margin-bottom: 0;
	}
}
